import Sidebar from './modules/sidebar/Sidebar'
import Authentication from './modules/Authentication'
import Navigation from './modules/header/Navigation'
import Dropdown from './modules/header/Dropdown'
import SearchWidget from './modules/header/SearchWidget'
import Search from './modules/Search'
import Newswire from './modules/Newswire'
import PodcastPlayer from './modules/PodcastPlayer'
import Resizing from './modules/header/Resizing'
import Editions from './modules/header/Editions'
import SiteMenu from './modules/header/SiteMenu'
import PlayOnHover from './modules/PlayOnHover'
import ArticleLinks from './modules/ArticleLinks'
import LazyArticles from './modules/LazyArticles'
import VideoPlayer from './modules/VideoPlayer'
import FeaturedLinksScroller from './modules/FeaturedLinksScroller'
import Jobs from './modules/Jobs'
import MarketsToggle from './modules/MarketsToggle'
import Swiper from 'swiper/bundle'
import NewsAnalytics from './modules/NewsAnalytics'
import PurchasableArticles from './modules/PurchasableArticles'
import ShareLinks from './modules/ShareLinks'
import EnergyViewer from './modules/EnergyViewer'

const energyViewer = new EnergyViewer()
if (document.querySelector('main.energy-viewer')) {
  energyViewer.app()
}
if (document.querySelector('.enappsys[data-sidebar="true"]')) {
  energyViewer.widget()
}

let purchasableArticles = new PurchasableArticles()
let videoRows = document.querySelectorAll('.video-category__inner')
let bodyStyles = getComputedStyle(document.body);
let container = parseInt(bodyStyles.getPropertyValue('--container'))
let leftoverSpace = ((window.innerWidth - container) / 2) + "px";

if (container === 100) {
  leftoverSpace = bodyStyles.getPropertyValue('--outer-margin');
}
if (videoRows) {
  videoRows.forEach(row => {
    row.style.paddingLeft = leftoverSpace
    new Swiper(row, {
      breakpoints: {
        320: {
          slidesPerView: 1.25,
        },
        620: {
          slidesPerView: 2.25,
          slidesPerGroup: 2,
        },
        1024: {
          slidesPerView: 3.25,
          slidesPerGroup: 3,
        },
        1280: {
          slidesPerView: 4.25,
          slidesPerGroup: 4
        },
      },
      freeMode: {
        enabled: true,
        sticky: false,
        momentumBounce: false,
      },
      spaceBetween: 16,
      updateOnWindowResize: true,
      mousewheel: {
        enabled: true,
        forceToAxis: true
      },
      navigation: {
        nextEl: row.querySelector('.swiper-button-next'),
        prevEl: row.querySelector('.swiper-button-prev')
      },

      // Vis pil høyre på hover
      // vis pil venstre på hover hvis
    })
  })
  addEventListener('resize', () => {
    container = parseInt(bodyStyles.getPropertyValue('--container'))
    leftoverSpace = ((window.innerWidth - container) / 2) + "px";
    if (container === 100) {
      leftoverSpace = bodyStyles.getPropertyValue('--outer-margin');
    }
    videoRows.forEach(row => {
      row.style.paddingLeft = leftoverSpace
    })
  })
}

// const authentication = new Authentication() // Phase 2
if (document.querySelector('.article__sharing')) {
  const sl = new ShareLinks()
}
const navigation = new Navigation()
const searchWidget = new SearchWidget()
const dropdown = new Dropdown()
const search = new Search()
const newswire = new Newswire()
const siteMenu = new SiteMenu()
const podcastPlayer = new PodcastPlayer()
const playOnHover = new PlayOnHover()
const articleLinks = new ArticleLinks()
const videoPlayer = new VideoPlayer()
const featuredLinksScroller = new FeaturedLinksScroller()
const newsAnalytics = new NewsAnalytics()
newsAnalytics.configure('https://news-api.montelgroup.com', 'MontelNews')
if (document.querySelector('.article')) {
  const article = document.querySelector('.article[data-id]')
  if (article) {
    const id = article.getAttribute('data-id')
    newsAnalytics.beginRead(id)
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
          newsAnalytics.endRead(id);
      } else {
        newsAnalytics.beginRead(id)
      }
    });
    window.addEventListener('pagehide', () => {
        newsAnalytics.endRead(id);
    });
  }
}


Resizing.listener()
Editions.listener()

if (document.querySelector('.page-tag, .page-section')) {
  const lazyArticles = new LazyArticles()
}
if (document.querySelector('.jobs-form')) {
  const jobs = new Jobs()
}

if (document.querySelector('.markets')) {
  const marketsToggle = new MarketsToggle()
}


// Hide or show sidebars, depending on screen-width
const sidebars = document.querySelectorAll('.news-list--with-sidebar aside')
if (sidebars) {
  sidebars.forEach(sidebar => new Sidebar(sidebar))
}

// const charts = document.querySelectorAll('.enappsys')
// charts.forEach(chart => {
//   new EnappsysChart(chart)
// })

